export const API_BASE_URL = import.meta.env.BROWSER_API_BASE_URL as string;
export const API_V3_BASE_URL = import.meta.env.BROWSER_API_V3_BASE_URL as string;
export const AMPLITUDE_KEY = import.meta.env.BROWSER_AMPLITUDE_KEY as string;

export const BRAZE_API_ENDPOINT = import.meta.env.BROWSER_BRAZE_API_ENDPOINT as string;
export const BRAZE_API_KEY = import.meta.env.BROWSER_BRAZE_API_KEY as string;
export const BROWSER_ENVIRONMENT = import.meta.env.BROWSER_ENVIRONMENT;
export const BROWSER_TRACK_URL = import.meta.env.BROWSER_TRACK_URL as string;
export const GOOGLE_CLIENT_ID = import.meta.env.BROWSER_GOOGLE_CLIENT_ID;

export const CDN_URL = 'https://cdn.brain.fm';

export const FACEBOOK_APP_ID = import.meta.env.BROWSER_FACEBOOK_APP_ID;

export const FACEBOOK_PIXEL_ID = import.meta.env.BROWSER_FACEBOOK_PIXEL_ID as string;

export const GA_TRACKING_ID = import.meta.env.BROWSER_GA_TRACKING_ID;

export const LAUNCH_DARKLY = import.meta.env.BROWSER_LAUNCH_DARKLY as string;

export const SELF_URL = import.meta.env.BROWSER_SELF_URL;
export const TEAMS_APP_URL = import.meta.env.BROWSER_TEAMS_APP_URL;
export const SENTRY_DSN_KEY = import.meta.env.BROWSER_SENTRY_DSN_KEY as string;
export const STRIPE_KEY = import.meta.env.BROWSER_STRIPE_KEY as string;

export const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.brainfm.app&hl=en_US';
export const IOS_DOWNLOAD_LINK =
  'https://itunes.apple.com/us/app/brain-fm-music-for-the-brain/id1110684238?mt=8';

export const NOOP = () => {};

export const REFER_A_FRIEND_LEGACY_PROMO_PRODUCTION = 'promo_1KC6DhDxyvLufNfyZ264p2Za';
export const REFER_A_FRIEND_LEGACY_PROMO_DEVELOPMENT = 'promo_1KC5T2DxyvLufNfyj7UNedUH';
export const REFER_A_FRIEND_PROMO_PRODUCTION = 'promo_1OADzEDxyvLufNfyQFlIxjzX';
export const REFER_A_FRIEND_PROMO_DEVELOPMENT = 'promo_1OADypDxyvLufNfyVKH0sSRQ';
export const REFER_A_FRIEND_LINK = `${SELF_URL}/payment?extended_promo=30&utm_source=referafriend`;
export const LISTENING_MILESTONES_TO_TRACK = [5, 30, 60, 90];

export const DEEP_LINK_ROUTES = [
  '/intentions/home',
  '/intentions/share',
  '/intentions/pay',
  '/intentions/account',
  '/intentions/justAddedMusic',
  '/intentions/focusFavorites',
];

export const FALLBACK_TRACK_IMAGE = 'https://cdn.brain.fm/images/default_track_image.png';

// ContainerIds for ToastContainers
export const MAIN_TOAST = 'main-toast';
export const GLOBAL_TOAST = 'global-toast';

export const RECOMBEE_DATABASE_ID = import.meta.env.BROWSER_RECOMBEE_DATABASE_ID as string;
export const RECOMBEE_PUBLIC_TOKEN = import.meta.env.BROWSER_RECOMBEE_PUBLIC_TOKEN as string;

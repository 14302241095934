import { Assets } from '../../../../utils/assets';
import {
  Button,
  ButtonVariantSizes,
  ButtonVariants,
  FontColors,
  MenuItem,
  MenuItemIcons,
  Text,
  TextBold,
} from '../../../Cortex';
import { PRODUCT_SURVEY_BANNER_URL } from './ProductSurveyBanner.constants';
import * as S from './ProductSurveyBanner.styles';

type Props = {
  onClickClose: () => void;
  onClickSurvey: () => void;
};

export function ProductSurveyBannerDisplay(props: Props) {
  return (
    <S.Wrapper>
      <S.BannerImageBgLeft
        alt={Assets.images.bannerLeftBlur.alt}
        src={Assets.images.bannerLeftBlur.url}
      />
      <S.BannerImageBgRight
        alt={Assets.images.bannerRightBlur.alt}
        src={Assets.images.bannerRightBlur.url}
      />
      <S.Body>
        <S.CopyWrapper>
          <TextBold size={16}>Help us improve!</TextBold>
          <Text color={FontColors.WhiteTransparent} size={14}>
            Take this quick survey so we can create an even better Brain.fm.
          </Text>
        </S.CopyWrapper>
      </S.Body>
      <S.Body>
        <S.ButtonWrapper href={PRODUCT_SURVEY_BANNER_URL} target="_blank">
          <Button
            keepTextCase={true}
            size={ButtonVariantSizes.Small}
            style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
            variant={ButtonVariants.Secondary}
            onClick={props.onClickSurvey}
          >
            TAKE QUICK SURVEY
          </Button>
        </S.ButtonWrapper>
        <MenuItem icon={MenuItemIcons.Close} isActive={true} onClickIcon={props.onClickClose} />
      </S.Body>
    </S.Wrapper>
  );
}

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 68px;
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%),
    #0d0c11;
  border-radius: 8px;
  border: 1px solid #3d3d3d;
  color: white;
  display: flex;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  justify-content: space-between;
  padding: 12px 12px 12px 20px;
  margin-bottom: 8px;
  z-index: 0;
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
  z-index: 2;
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.a`
  margin-right: 1rem;
  text-decoration: none;
`;

export const BannerImageBgLeft = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const BannerImageBgRight = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

import { CDN_URL } from '@Globals';

import { AssetsInterface } from '../types/utils/assets';

export const Assets: AssetsInterface = {
  images: {
    macAppBannerIcon: {
      url: `${CDN_URL}/images/mac_app_banner_icon.png`,
      alt: 'Mac App Icon',
    },
    logo: {
      url: `${CDN_URL}/icons/bfm-logo.svg`,
      alt: 'BrainFM logo',
    },
    logoWithText: {
      url: `${CDN_URL}/images/bfm_logo_with_text.webp`,
      alt: 'BrainFM logo',
    },
    logoFull: {
      url: `${CDN_URL}/images/logo-full.svg`,
      alt: 'BrainFM logo',
    },
    loadingLogoWithText: {
      url: `${CDN_URL}/images/loading-logo-and-text.gif`,
      alt: 'brain.fm loading animation',
    },
    focusWoman: {
      url: `${CDN_URL}/images/focus-woman.png`,
      alt: 'A woman focusing at her desk.',
    },
    relaxWoman: {
      url: `${CDN_URL}/images/relax-woman.png`,
      alt: 'A woman relaxing at a table.',
    },
    filterMusicWaves: {
      url: `${CDN_URL}/images/amplitude.png`,
      alt: 'Amplitude Waves image',
    },
    waveOne: {
      url: `${CDN_URL}/images/wave-1.svg`,
      alt: 'Amplitude Waves image',
    },
    waveTwo: {
      url: `${CDN_URL}/images/wave-2.svg`,
      alt: 'Amplitude Waves image',
    },
    waveThree: {
      url: `${CDN_URL}/images/wave-3.svg`,
      alt: 'Amplitude Waves image',
    },
    waveFour: {
      url: `${CDN_URL}/images/wave-4.svg`,
      alt: 'Amplitude Waves image',
    },
    waveFive: {
      url: `${CDN_URL}/images/wave-5.svg`,
      alt: 'Amplitude Waves image',
    },
    waveSix: {
      url: `${CDN_URL}/images/wave-6.svg`,
      alt: 'Amplitude Waves image',
    },
    backgroundVector: {
      url: `${CDN_URL}/images/background-vector.png`,
      alt: 'Background Vector',
    },
    focusGirl: {
      url: `${CDN_URL}/images/focus-girl.png`,
      alt: 'focus girl image',
    },
    focusedGirl: {
      url: `${CDN_URL}/images/focused_girl.webp`,
      alt: 'focused',
    },
    songPlaceholder: {
      url: `${CDN_URL}/images/song_header_placeholder.svg`,
      alt: 'sunset',
    },
    onboardingCountdown: {
      url: `${CDN_URL}/images/onboarding/onboarding_countdownr.svg`,
      alt: 'A picture of the brain.fm application',
    },
    onboardingGraph: {
      url: `${CDN_URL}/images/onboarding/onboarding_graph.svg`,
      alt: 'A graph of focus and performance',
    },
    bowtie: {
      url: `${CDN_URL}/images/bowtie.svg`,
      alt: 'Bowtie',
    },
    blurBg: {
      url: `${CDN_URL}/images/blur_bg.png`,
      alt: 'Background Blur',
    },
    circleBlurBg: {
      url: `${CDN_URL}/images/circle_blur_bg.svg`,
      alt: 'Background Blur',
    },
    circleBlurTopBg: {
      url: `${CDN_URL}/images/circle_of_blurs_top.webp`,
      alt: 'Background Blur',
    },
    subscriptionSuccess: {
      url: `${CDN_URL}/images/subscriptionSuccess.png`,
      alt: 'Subscription Success',
    },
    gradientCheck: {
      url: `${CDN_URL}/images/gradient-check.png`,
      alt: 'Gradient Check',
    },

    focusMentalStateBgPng: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_small_aura.png`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgWebp: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_small_aura.webp`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_large_aura.png`,
      alt: 'Lady working in focus mode',
    },
    focusMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/focus/focus_mental_state_bg_large_aura.webp`,
      alt: 'Lady working in focus mode',
    },

    relaxMentalStateBgPng: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_small_aura.png`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgWebp: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_small_aura.webp`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_large_aura.png`,
      alt: 'Man relaxing on a chair',
    },
    relaxMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/relax/relax_mental_state_bg_large_aura.webp`,
      alt: 'Man relaxing on a chair',
    },

    sleepMentalStateBgPng: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_small_aura.png`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgWebp: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_small_aura.webp`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_large_aura.png`,
      alt: 'Woman sleeping on a bed',
    },
    sleepMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/sleep/sleep_mental_state_bg_large_aura.webp`,
      alt: 'Woman sleeping on a bed',
    },

    meditateMentalStateBgPng: {
      url: `${CDN_URL}/images/meditate/meditate_mental_state_bg_small_aura.png`,
      alt: 'Man meditating on a mat',
    },
    meditateMentalStateBgWebp: {
      url: `${CDN_URL}/images/meditate/meditate_mental_state_bg_small_aura.webp`,
      alt: 'Man meditating on a mat',
    },
    meditateMentalStateBgAuraPng: {
      url: `${CDN_URL}/images/meditate/meditate_mental_state_bg_large_aura.png`,
      alt: 'Man meditating on a mat',
    },
    meditateMentalStateBgAuraWebp: {
      url: `${CDN_URL}/images/meditate/meditate_mental_state_bg_large_aura.webp`,
      alt: 'Man meditating on a mat',
    },
    fiveStars: {
      url: `${CDN_URL}/images/onboarding/fiveStarsYellow.png`,
      alt: 'five stars',
    },
    fiveStarsWhite: {
      url: `${CDN_URL}/images/onboarding/fiveStars.png`,
      alt: 'five stars',
    },
    clouds: {
      url: `${CDN_URL}/images/onboarding/clouds.png`,
      alt: 'clouds',
    },
    focusChallenge: {
      url: `${CDN_URL}/images/onboarding/focusChallenge.png`,
      alt: 'woman working at desk',
    },
    poweredByStripe: {
      url: `${CDN_URL}/images/poweredByStripe.svg`,
      alt: 'Stripe',
    },
    hubSpotReview: {
      url: `${CDN_URL}/images/hub_spot_review_2.webp`,
      alt: 'Cancellation',
    },
    januaryPromoReview: {
      url: `${CDN_URL}/images/january_promo_review.webp`,
      alt: 'January Promo',
    },
    refer: {
      url: `${CDN_URL}/images/refer-a-friend.webp`,
      alt: 'Refer a friend',
    },
    highlight: {
      url: `${CDN_URL}/images/highlight.svg`,
      alt: 'Highlight',
    },
    headphoneEarbud: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__headphones.webp`,
      alt: 'Headphone Earbud',
    },
    clock10: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__clock_10.webp`,
      alt: 'Clock 10',
    },
    clock15: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__clock_15.webp`,
      alt: 'Clock 15',
    },
    clock30: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__clock_30.webp`,
      alt: 'Clock 30',
    },
    workingOnLaptop: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__laptop.webp`,
      alt: 'Working On A Laptop',
    },
    sleepingOnBed: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__sleep.webp`,
      alt: 'Sleeping On A Bed',
    },
    restingOnChair: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__relax.webp`,
      alt: 'Resting On A Chair',
    },
    sittingOnFloor: {
      url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__meditate.webp`,
      alt: 'Sitting On A Floor',
    },
    congratulations: {
      url: `${CDN_URL}/images/congratulations.webp`,
      alt: 'Congratulations',
    },
    specialOffer: {
      url: `${CDN_URL}/images/special_offer.webp`,
      alt: 'Special Offer',
    },
    heartConfetti: {
      url: `${CDN_URL}/images/heart_confetti.webp`,
      alt: 'Heart Confetti',
    },
    purpleAura: {
      url: `${CDN_URL}/images/purple_aura.svg`,
      alt: 'Purple Aura',
    },
    blueAura: {
      url: `${CDN_URL}/images/blue_aura.webp`,
      alt: 'Purple Aura',
    },
    genres: {
      url: `${CDN_URL}/images/genres.svg`,
      alt: 'Genres',
    },
    circlesGradient: {
      url: `${CDN_URL}/images/circles_gradient.svg`,
      alt: 'Circles',
    },
    allGradientCircles: {
      url: `${CDN_URL}/images/circle_of_blurs.webp`,
      alt: 'Gradient Circles',
    },
    keepAccess: {
      url: `${CDN_URL}/images/keep_access.webp`,
      alt: 'Keep Access',
    },
    keepAccessAura: {
      url: `${CDN_URL}/images/keep_access_aura.webp`,
      alt: 'Keep Access Aura',
    },
    planSelectionLeftBg: {
      url: `${CDN_URL}/images/plan_selection/bg_left.webp`,
      alt: 'Plan Selection Left Background',
    },
    planSelectionLeftImg: {
      url: `${CDN_URL}/images/plan_selection/img_left.webp`,
      alt: 'Plan Selection Left Image',
    },
    planSelectionRightBg: {
      url: `${CDN_URL}/images/plan_selection/bg_right.webp`,
      alt: 'Plan Selection Right Background',
    },
    planSelectionRightImg: {
      url: `${CDN_URL}/images/plan_selection/img_right.webp`,
      alt: 'Plan Selection Right Image',
    },
    authAuraBig: {
      url: `${CDN_URL}/images/auth_aura_big.webp`,
      alt: 'Brain.fm color aura',
    },
    authAuraSmall: {
      url: `${CDN_URL}/images/signup_aura_bottom.webp`,
      alt: 'Brain.fm color aura',
    },
    activationBg: {
      url: `${CDN_URL}/images/activate_bg.webp`,
      alt: 'Activation Bg',
    },
    activationCircles: {
      url: `${CDN_URL}/images/activate_circles.webp`,
      alt: 'Activation Circles',
    },
    congrats: {
      url: `${CDN_URL}/images/congrats.webp`,
      alt: 'Congrats',
    },
    badges: {
      favorites: {
        0: {
          url: `${CDN_URL}/images/badges/favorites/favorites_0.png`,
          alt: 'Favorites milestone: 0',
        },
        1: {
          url: `${CDN_URL}/images/badges/favorites/favorites_1.png`,
          alt: 'Favorites milestone: 1',
        },
        3: {
          url: `${CDN_URL}/images/badges/favorites/favorites_3.png`,
          alt: 'Favorites milestone: 3',
        },
        5: {
          url: `${CDN_URL}/images/badges/favorites/favorites_5.png`,
          alt: 'Favorites milestone: 5',
        },
        10: {
          url: `${CDN_URL}/images/badges/favorites/favorites_10.png`,
          alt: 'Favorites milestone: 10',
        },
        25: {
          url: `${CDN_URL}/images/badges/favorites/favorites_25.png`,
          alt: 'Favorites milestone: 25',
        },
        50: {
          url: `${CDN_URL}/images/badges/favorites/favorites_50.png`,
          alt: 'Favorites milestone: 50',
        },
        100: {
          url: `${CDN_URL}/images/badges/favorites/favorites_100.png`,
          alt: 'Favorites milestone: 100',
        },
      },
      streak: {
        0: {
          url: `${CDN_URL}/images/badges/streak/streak_0.png`,
          alt: 'Streak milestone: 0',
        },
        1: {
          url: `${CDN_URL}/images/badges/streak/streak_1.png`,
          alt: 'Streak milestone: 1',
        },
        3: {
          url: `${CDN_URL}/images/badges/streak/streak_3.png`,
          alt: 'Streak milestone: 3',
        },
        5: {
          url: `${CDN_URL}/images/badges/streak/streak_5.png`,
          alt: 'Streak milestone: 5',
        },
        10: {
          url: `${CDN_URL}/images/badges/streak/streak_10.png`,
          alt: 'Streak milestone: 10',
        },
        25: {
          url: `${CDN_URL}/images/badges/streak/streak_25.png`,
          alt: 'Streak milestone: 25',
        },
        50: {
          url: `${CDN_URL}/images/badges/streak/streak_50.png`,
          alt: 'Streak milestone: 50',
        },
        100: {
          url: `${CDN_URL}/images/badges/streak/streak_100.png`,
          alt: 'Streak milestone: 100',
        },
      },
    },
    blackFriday2024SignUp: {
      url: `${CDN_URL}/images/bf-2024-sign-up.png`,
      alt: 'Cyber Monday 2024 Sign Up',
    },
    firstActivityModal: {
      blur: {
        url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__blur_circle_low.webp`,
        alt: '',
      },
      neuralEffectLevel: {
        low: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__low_nel.webp`,
          alt: 'Low Neural Effect Level',
        },
        medium: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__medium_nel.webp`,
          alt: 'Medium Neural Effect Level',
        },
        high: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal__high_nel.webp`,
          alt: 'High Neural Effect Level',
        },
      },
      genres: {
        modernBeats: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_modern_beats.webp`,
          alt: 'Modern Beats Genre',
        },
        epicAmbientSpacey: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_epic_ambient_spacey.webp`,
          alt: 'Epic, Ambient, and Spacey Genre',
        },
        natureSoundscapes: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_modern_nature_soundscapes.webp`,
          alt: 'Nature Soundscapes Genre',
        },
        unplugged: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_modern_unplugged.webp`,
          alt: 'Unplugged Genre',
        },
        atmospheric: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_atmospheric.webp`,
          alt: 'Atmospheric Genre',
        },
        atmosphericElectronic: {
          url: `${CDN_URL}/images/first_activity_modal/first_activity_modal_atmospheric_electronic.webp`,
          alt: 'Atmospheric and Electronic Genre',
        },
      },
    },
    milestonesCirclesOfBlur: {
      url: `${CDN_URL}/images/milestones_widget_circles_of_blur.webp`,
      alt: 'milestones',
    },
    appDownloadIos: {
      url: `${CDN_URL}/images/app_download_ios.webp`,
      alt: 'App Download iOS',
    },
    appDownloadAndroid: {
      url: `${CDN_URL}/images/app_download_android.webp`,
      alt: 'App Download Android',
    },
    appDownloadBackground: {
      url: `${CDN_URL}/images/downloadAppCircles.webp`,
      alt: 'App Download Background',
    },
    subscriptionWidgetFocus: {
      url: `${CDN_URL}/images/subscription_widget_focus.webp`,
      alt: 'Subscription Widget Focus',
    },
    subscriptionSaveBlur: {
      url: `${CDN_URL}/images/subscription_save_blur.webp`,
      alt: 'Subscription Save Blur',
    },
    subscriptionSaveBadge: {
      url: `${CDN_URL}/images/subscription_save_badge.webp`,
      alt: 'Subscription Save Badge',
    },
    timer30mins: {
      url: `${CDN_URL}/images/30-mins-timer.webp`,
      alt: '30-minute timer',
    },
    bannerLeftBlur: {
      url: `${CDN_URL}/images/banner_left_blur.webp`,
      alt: 'Banner Left Blur',
    },
    bannerRightBlur: {
      url: `${CDN_URL}/images/banner_right_blur.webp`,
      alt: 'Banner Right Blur',
    },
  },
  videos: {
    session: {
      focus: {
        url: `${CDN_URL}/videos/background_gradient_640x480.mp4`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_focus.jpg`, // video thumbnail
        type: 'video/mp4',
      },
      relax: {
        url: `${CDN_URL}/videos/relax_background.mov`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_relax.jpg`,
        type: 'video/mp4',
      },
      sleep: {
        url: `${CDN_URL}/videos/sleep_background.mov`,
        poster: `${CDN_URL}/video_posters/background_gradient_thumbnail_sleep.jpg`,
        type: 'video/mp4',
      },
    },
  },
  icons: {
    playStoreBlack: {
      url: `${CDN_URL}/icons/play_store_black.svg`,
      alt: 'Play Store',
    },
    appleBlack: {
      url: `${CDN_URL}/icons/apple_black.svg`,
      alt: 'Apple Store',
    },
    focus: {
      url: `${CDN_URL}/icons/focus_icon.svg`,
      alt: 'Focus Icon',
    },
    relax: {
      url: `${CDN_URL}/icons/relax_icon.svg`,
      alt: 'Relax Icon',
    },
    sleep: {
      url: `${CDN_URL}/icons/sleep_icon.svg`,
      alt: 'Sleep Icon',
    },
    android: {
      url: `${CDN_URL}/icons/android_icon.svg`,
      alt: 'Android Icon',
    },
    apple: {
      url: `${CDN_URL}/icons/apple_icon.svg`,
      alt: 'Apple Icon',
    },
    close: {
      url: `${CDN_URL}/icons/close_icon.svg`,
      alt: 'Close icon',
    },
    close_white: {
      url: `${CDN_URL}/icons/close_icon_white.svg`,
      alt: 'Close icon',
    },
    download: {
      url: `${CDN_URL}/icons/download_icon.svg`,
      alt: 'Offline Access',
    },
    facebook: {
      url: `${CDN_URL}/icons/facebook_icon.svg`,
      alt: 'Facebook Icon',
    },
    twitter: {
      url: `${CDN_URL}/icons/twitter_outline.svg`,
      alt: 'Twitter Icon',
    },
    warning: {
      url: `${CDN_URL}/icons/warning_icon.svg`,
      alt: 'Warning Icon',
    },
    warningWhite: {
      url: `${CDN_URL}/icons/warning_icon_white.svg`,
      alt: 'Warning White Icon',
    },
    settings: {
      url: `${CDN_URL}/icons/settings.svg`,
      alt: 'Settings Icon',
    },
    userAvatar: {
      url: `${CDN_URL}/icons/user_icon.svg`,
      alt: 'User Avatar',
    },
    loading: {
      url: `${CDN_URL}/icons/loading_icon.svg`,
      alt: 'Loading Icon',
    },
    play: {
      url: `${CDN_URL}/icons/play_icon.svg`,
      alt: 'Play Icon',
    },
    playSolid: {
      url: `${CDN_URL}/icons/play_solid.svg`,
      alt: 'Play Icon',
    },
    pause: {
      url: `${CDN_URL}/icons/pause_icon.svg`,
      alt: 'Pause Icon',
    },
    skip: {
      url: `${CDN_URL}/icons/skip_icon.svg`,
      alt: 'Pause Icon',
    },
    heart: {
      url: `${CDN_URL}/icons/heart_icon.svg`,
      alt: 'Heart Icon',
    },
    heartFilled: {
      url: `${CDN_URL}/icons/heart_filled.svg`,
      alt: 'Heart Filled Icon',
    },
    speakerOn: {
      url: `${CDN_URL}/icons/speaker_on.svg`,
      alt: 'Speaker On Icon',
    },
    speakerMuted: {
      url: `${CDN_URL}/icons/speaker_muted.svg`,
      alt: 'Speaker Muted Icon',
    },

    backArrow: {
      url: `${CDN_URL}/icons/arrow_left_icon.svg`,
      alt: 'Go Back',
    },
    arrowLeft: {
      url: `${CDN_URL}/icons/arrow_left_icon.svg`,
      alt: 'Arrow pointing left',
    },

    calendarDay: {
      url: `${CDN_URL}/icons/calendar-day-icon.svg`,
      alt: 'Calendar indicating 1 day',
    },
    calendarWeek: {
      url: `${CDN_URL}/icons/calendar-week-icon.svg`,
      alt: 'Calendar indicating 1 week',
    },
    calendarAll: {
      url: `${CDN_URL}/icons/calendar-infinite-icon.svg`,
      alt: 'Calendar indicating infinite days',
    },
    offlineAccess: {
      url: `${CDN_URL}/icons/offline_access_icon.svg`,
      alt: 'Red globe with magnifier',
    },
    unlimitedListening: {
      url: `${CDN_URL}/icons/unlimited_listening_icon.svg`,
      alt: 'Infinity symbol',
    },
    customExperience: {
      url: `${CDN_URL}/icons/personalized_experience_icon.svg`,
      alt: 'Laser on a grid',
    },
    alarm: {
      url: `${CDN_URL}/icons/alarm_icon.png`,
      alt: 'Alarm',
    },
    clockWise: {
      url: `${CDN_URL}/icons/clockwise_icon.png`,
      alt: 'Clockwise',
    },
    checkCircle: {
      url: `${CDN_URL}/icons/check_circle_icon.png`,
      alt: 'Check Circle',
    },
    power: {
      url: `${CDN_URL}/icons/power_icon.svg`,
      alt: 'Power Level',
    },
    question: {
      url: `${CDN_URL}/icons/question_icon.svg`,
      alt: 'Question',
    },
    shuffle: {
      url: `${CDN_URL}/icons/shuffle_icon.svg`,
      alt: 'Shuffle',
    },
    locker: {
      url: `${CDN_URL}/icons/lock.svg`,
      alt: 'Locker',
    },
    guarantee: {
      url: `${CDN_URL}/icons/guarantee.svg`,
      alt: 'Guarantee',
    },
    checkmark: {
      url: `${CDN_URL}/icons/checkmark.svg`,
      alt: 'Checkmark',
    },
    checkmarkBlack: {
      url: `${CDN_URL}/icons/checkmark_black.svg`,
      alt: 'Checkmark',
    },
    checkmarkGradient: {
      url: `${CDN_URL}/icons/checkmark_gradient.png`,
      alt: 'Checkmark Gradient',
    },
    infoBlack: {
      url: `${CDN_URL}/icons/info_black.svg`,
      alt: 'Checkmark',
    },
    taskFilled: {
      url: `${CDN_URL}/icons/task_filled_icon.svg`,
      alt: 'TaskFilled',
    },
    taskUnfilled: {
      url: `${CDN_URL}/icons/task_unfilled_icon.svg`,
      alt: 'TaskUnfilled',
    },
    explore: {
      url: `${CDN_URL}/icons/sessionHeader/explore.svg`,
      alt: 'explore',
    },
    profile: {
      url: `${CDN_URL}/icons/sessionHeader/profile.svg`,
      alt: 'profile',
    },
    timerSettings: {
      url: `${CDN_URL}/icons/sessionHeader/timer.svg`,
      alt: 'timerSettings',
    },
    musicPreferences: {
      url: `${CDN_URL}/icons/sessionHeader/preferences.svg`,
      alt: 'musicPreferences',
    },
    refer: {
      url: `${CDN_URL}/icons/refer.svg`,
      alt: 'Refer',
    },
    referBold: {
      url: `${CDN_URL}/icons/referBold.svg`,
      alt: 'Refer',
    },
    link: {
      url: `${CDN_URL}/icons/link.svg`,
      alt: 'Link',
    },
    rocket: {
      url: `${CDN_URL}/icons/rocket.svg`,
      alt: 'Rocket',
    },
    trophy: {
      url: `${CDN_URL}/icons/trophy_icon.svg`,
      alt: 'Trophy',
    },
    road: {
      url: `${CDN_URL}/icons/road_icon.svg`,
      alt: 'Road',
    },
    diamond: {
      url: `${CDN_URL}/icons/diamond_icon.svg`,
      alt: 'Diamond',
    },
    promoTag: {
      url: `${CDN_URL}/icons/promo_tag.svg`,
      alt: 'Promo Tag',
    },
    greenCircleCheck: {
      url: `${CDN_URL}/icons/green_circle_check.svg`,
      alt: 'Green Circle Check',
    },
    clock: {
      url: `${CDN_URL}/icons/clock.svg`,
      alt: 'Clock',
    },
    calendar: {
      url: `${CDN_URL}/icons/calendar.svg`,
      alt: 'Calendar',
    },
    arrowUp: {
      url: `${CDN_URL}/icons/arrowUp.svg`,
      alt: 'Arrow Up',
    },
    check: {
      url: `${CDN_URL}/icons/milestones/check.svg`,
      alt: 'Check',
    },
    steps: {
      url: `${CDN_URL}/icons/milestones/steps.svg`,
      alt: 'Check',
    },
    circleFadeUp: {
      url: `${CDN_URL}/icons/circle_fading_arrow_up.svg`,
      alt: 'Circle Fade Up',
    },
    googlePlayStore: {
      url: `${CDN_URL}/icons/google_play_store_icon.svg`,
      alt: 'Google Play Store',
    },
    dropdownArrow: {
      url: `${CDN_URL}/icons/dropdown-arrow.svg`,
      alt: 'Dropdown arrow',
    },
  },
  emojis: {
    eWave: {
      url: `${CDN_URL}/emojis/e_wave.png`,
      alt: 'Hande waving',
    },
    monkeyNoPeek: {
      url: `${CDN_URL}/emojis/monkey_no_peek.png`,
      alt: '',
    },
  },
  fonts: {},
  locales: {},
  sounds: {
    threeChimes: {
      url: `${CDN_URL}/sounds/three-chimes.wav`,
    },
    chime: {
      url: `${CDN_URL}/sounds/chime.wav`,
    },
    voiceBreakEnd: {
      url: `${CDN_URL}/sounds/breakintervalend.wav`,
    },
    voiceWorkEnd: {
      url: `${CDN_URL}/sounds/workintervalend.wav`,
    },
  },
};

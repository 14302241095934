import { useFeatureFlagsState } from '@Utils';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { homeScreenLogEvent } from '../../actions/analytics';
import { clearPostOnboardingRedirectData } from '../../domains/Onboarding/utils/clearPostOnboardingRedirectData';

import { RootReducerType } from '../../reducers';

import { useDynamicMentalStates } from '../../api/modules/DynamicMentalStates';
import { DynamicMentalState } from '@Model';
import {
  ONBOARDING_MENTAL_STATE_ID_KEY,
  ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY,
} from '../../domains/Onboarding/constants';
import { ANIMATION_TIMING_MS } from './constants';
import { HomePresentation } from './Home.presentation';
import { CardHoverState } from './Home.types';
import { Analytics } from '../../utils/analytics';
import { uiSliceActions } from '../../reducers/uiReducer';
import { selectCurrentTrack } from '../../selectors/music';
import { getWebServingAlgorithmPrefix } from '../../domains/Utils/useAmplitudeExperiments';

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const [selectedState, setSelectedState] = useState<string>('');
  const [hoverState, setHoverState] = useState<CardHoverState>('none');
  const lastPlayedDynamicActivities = useSelector(
    (state: RootReducerType) => state.user.lastPlayedDynamicActivities,
  );
  const currentTrack = useSelector(selectCurrentTrack);
  const currentSessionDynamicActivityId = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity?.id,
  );
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);

  const { data: dynamicMentalStates, isFetched: isFetchedDynamicActivities } =
    useDynamicMentalStates();

  const { isSettled: isFeatureFlagsReceived } = useFeatureFlagsState();

  const navigate = useNavigate();

  const navigateToActivity = useCallback(
    (activityId: string) => () => {
      Analytics.logEvent('test_recommended_activity_clicked');
      navigate(`/player/${activityId}`, { state: { useSavedTimer: true } });
    },
    [navigate],
  );

  useEffect(() => {
    // TODO: move to redux or come up with a better solution.
    const onboardingMentalStateId = sessionStorage.getItem(ONBOARDING_MENTAL_STATE_ID_KEY);
    const onboardingDynamicActivityId = sessionStorage.getItem(ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY);
    if ((!onboardingMentalStateId && !onboardingDynamicActivityId) || !isFeatureFlagsReceived) {
      return;
    }

    if (onboardingDynamicActivityId) {
      navigateToActivity(onboardingDynamicActivityId)();
      clearPostOnboardingRedirectData();
      return;
    }

    if (isFetchedDynamicActivities && dynamicMentalStates) {
      const mentalState = dynamicMentalStates.find(
        mentalState => mentalState.id === onboardingMentalStateId,
      );
      if (mentalState) {
        navigateToDynamicSessionPlayer(mentalState, { isFirstSession: true })();
        clearPostOnboardingRedirectData();
      }
    }
  }, [
    isFeatureFlagsReceived,
    dynamicMentalStates,
    isFetchedDynamicActivities,
    modalType,
    navigateToActivity,
  ]);

  useEffect(() => {
    dispatch(homeScreenLogEvent('home_screen_view'));
  }, [dispatch]);

  const navigateToDynamicSessionPlayer = useCallback(
    (mentalState: DynamicMentalState, params?: { isFirstSession?: boolean }) => () => {
      const defaultActivityId = params?.isFirstSession
        ? mentalState.yourFirstSessionActivity.id
        : mentalState.defaultActivity.id;
      const activity = lastPlayedDynamicActivities[mentalState.id] || defaultActivityId;

      setSelectedState(mentalState.id); // just for visual fade out effect
      setTimeout(
        () =>
          navigate(`/player/${getWebServingAlgorithmPrefix()}${activity}`, {
            state: { useSavedTimer: true },
          }),
        ANIMATION_TIMING_MS,
      );
      dispatch(homeScreenLogEvent(`home_screen_start_${mentalState.id}`));
    },
    [navigate, dispatch, lastPlayedDynamicActivities, homeScreenLogEvent],
  );

  const onSetHoverState = useCallback(
    (state: CardHoverState) => () => {
      setHoverState(state);
    },
    [setHoverState],
  );

  const handleMiniPlayerClick = () => {
    navigate(`/player/${currentSessionDynamicActivityId}`);
  };

  const handleSideDeckToggle = () => {
    dispatch(uiSliceActions.setSideDeckOpen(!isSideDeckOpen));

    if (isSideDeckOpen) {
      dispatch(uiSliceActions.setSideDeckModal(null));
    }
  };

  return (
    <HomePresentation
      currentTrack={currentTrack}
      dynamicMentalStates={dynamicMentalStates || []}
      hoverState={hoverState}
      isLoading={!isFetchedDynamicActivities}
      isSideDeckOpen={isSideDeckOpen}
      navigateToDynamicSessionPlayer={navigateToDynamicSessionPlayer}
      selectedState={selectedState}
      onClickMiniPlayer={handleMiniPlayerClick}
      onClickSideDeckButton={handleSideDeckToggle}
      onSetHoverState={onSetHoverState}
    />
  );
};

import { Icon } from '@Cortex';
import { ActivitySelector } from '@Session';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ArrowLeftIcon from '../../../../assets/images/arrow_left_icon.svg';
import { Assets } from '../../../../utils/assets';
import { trackPlayerArrowNavigateBack } from '../../../../domains/Analytics/coreAnalytics';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { RootReducerType } from '../../../../reducers';
import SideBarClosedIcon from '../../../../assets/images/side_bar_closed_icon.svg';
import SideBarOpenIcon from '../../../../assets/images/side_bar_open_icon.svg';

import * as S from './SessionHeader.styles';

type Props = {
  onClickPreferences?: () => void;
  onClickTimerSettings?: () => void;
};

export const SessionHeader = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);

  const handleBackArrowClick = () => {
    trackPlayerArrowNavigateBack();
    navigate('/', { replace: true });
  };

  const handleSideDeckToggle = () => {
    dispatch(uiSliceActions.setSideDeckOpen(!isSideDeckOpen));

    if (isSideDeckOpen) {
      dispatch(uiSliceActions.setSideDeckModal(null));
    }
  };

  return (
    <S.Header>
      <S.LeftContainer>
        <S.BackButtonContainer>
          <S.HeaderIconContainer onClick={handleBackArrowClick}>
            <Icon isSquare size={24} src={ArrowLeftIcon} />
            <S.HeaderIconLabel>home</S.HeaderIconLabel>
          </S.HeaderIconContainer>
        </S.BackButtonContainer>
        <S.MobileLogoContainer>
          <Icon size={24} src={ArrowLeftIcon} onClick={handleBackArrowClick} />
        </S.MobileLogoContainer>

        <ActivitySelector />
      </S.LeftContainer>

      <S.RightContainer>
        <S.HeaderIconContainer data-testid="preferencesButton" onClick={props.onClickPreferences}>
          <Icon isSquare size={32} src={Assets.icons.musicPreferences.url} />
          <S.HeaderIconLabel>Preferences</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        {!isMobileView && (
          <S.HeaderIconContainer onClick={handleSideDeckToggle}>
            <Icon size={32} src={isSideDeckOpen ? SideBarClosedIcon : SideBarOpenIcon} />
            <S.HeaderIconLabel>{isSideDeckOpen ? 'Close' : 'Open'}</S.HeaderIconLabel>
          </S.HeaderIconContainer>
        )}
      </S.RightContainer>
    </S.Header>
  );
};

import { useSelector } from 'react-redux';
import { useIsSurveyBannerEnabled } from '../../../../Utils/useAmplitudeExperiments';
import { RootReducerType } from '../../../../../reducers';

export const useShouldRenderProductSurvey = () => {
  const isWebSurveyBannerEnabled = useIsSurveyBannerEnabled();
  const isDismissed = useSelector((state: RootReducerType) => state.ui.isProductSurveyDismissed);

  return isWebSurveyBannerEnabled && !isDismissed;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  SuccessModalType,
  ModalType,
  UiStateType,
  SessionModalContent,
  ExplorePanelTab,
  LibraryPanelTab,
  SideDeckTab,
  SideDeckTabProperties,
  SideDeckModalType,
} from '../types';
import { DynamicMentalState } from '@Model';

const initialState: UiStateType = {
  isPlayerAnimationEnabled: true,
  isReferAFriendDismissed: false,
  isProductSurveyDismissed: false,
  playerVolume: 0.8,
  modalType: null,
  sessionFinished: false,
  sessionModalContent: null,
  explorePanelTab: null,
  libraryPanelTab: null,
  successModal: {
    iconSrc: undefined,
    isDismissable: true,
    isOpen: false,
    title: null,
    description: null,
    actions: [],
  },
  shouldShowProTipsModal: false,
  shouldShowNELTipsModal: false,
  shouldShowFocusPersonalizationQuiz: false,
  shouldShowFavoriteModal: false,
  sideDeck: {
    isOpen: false,
    selectedTab: {
      tab: SideDeckTab.Library,
    },
    librarySelectedMentalState: null,
    exploreSelectedMentalState: null,
    modal: null,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // Sets the modal to display
    setModalType: (state, action: PayloadAction<ModalType | null>) => {
      state.modalType = action.payload;
      return state;
    },

    setSessionFinished: (state, action: PayloadAction<boolean>) => {
      state.sessionFinished = action.payload;
      return state;
    },

    setVolume: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        playerVolume: action.payload,
      };
    },

    setSuccessModal: (state, action: PayloadAction<Partial<SuccessModalType>>) => {
      state.successModal = {
        ...state.successModal,
        ...action.payload,
      };
    },

    setShouldShowProTipsModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowProTipsModal = action.payload;
    },

    setShouldShowNELTipsModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowNELTipsModal = action.payload;
    },

    setShouldShowFocusPersonalizationQuiz: (state, action: PayloadAction<boolean>) => {
      state.shouldShowFocusPersonalizationQuiz = action.payload;
    },

    setShouldShowFavoriteModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowFavoriteModal = action.payload;
    },

    setSessionModalContent: (
      state,
      action: PayloadAction<{
        sessionModalContent: SessionModalContent | null;
        origin?: string;
      }>,
    ) => {
      state.sessionModalContent = action.payload.sessionModalContent;
      state.sessionModalContentOrigin = action.payload.origin;
    },

    setExploreModalTab: (state, action: PayloadAction<ExplorePanelTab | null>) => {
      state.explorePanelTab = action.payload;
    },

    setLibraryPanelTab: (state, action: PayloadAction<LibraryPanelTab | null>) => {
      state.libraryPanelTab = action.payload;
    },

    setPlayerAnimationState: (state, action: PayloadAction<boolean>) => {
      state.isPlayerAnimationEnabled = action.payload;
    },

    setIsReferAFriendDismissed: (state, action: PayloadAction<boolean>) => {
      state.isReferAFriendDismissed = action.payload;
    },

    setIsProductSurveyDismissed: (state, action: PayloadAction<boolean>) => {
      state.isProductSurveyDismissed = action.payload;
    },

    setSideDeckOpen: (state, action: PayloadAction<boolean>) => {
      state.sideDeck.isOpen = action.payload;
    },

    setLibraryMentalState: (state, action: PayloadAction<DynamicMentalState>) => {
      state.sideDeck.librarySelectedMentalState = action.payload;
    },

    setExploreMentalState: (state, action: PayloadAction<DynamicMentalState>) => {
      state.sideDeck.exploreSelectedMentalState = action.payload;
    },

    setSideDeckTab: (state, action: PayloadAction<SideDeckTabProperties | null>) => {
      if (action.payload !== null) {
        state.sideDeck.selectedTab = action.payload;
      }
    },

    setSideDeckModal: (state, action: PayloadAction<SideDeckModalType | null>) => {
      state.sideDeck.modal = action.payload;
    },
  },
});

export const uiSliceActions = uiSlice.actions;
export default uiSlice.reducer;

const persistedUIReducer = persistReducer(
  {
    key: 'UIReducer',
    storage,
    whitelist: [
      'playerVolume',
      'isPlayerAnimationEnabled',
      'isReferAFriendDismissed',
      'isProductSurveyDismissed',
    ],
  },
  uiSlice.reducer,
);
export { persistedUIReducer };

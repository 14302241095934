export type OnboardingSpecification = {
  id: string;
  cards: OnboardingCardType[];
  quizSpecification?: OnboardingQuizSpecification;
};

export type OnboardingCardType =
  | OnboardingInfoCard
  | OnboardingInfoCardTwo
  | OnboardingVideoCard
  | OnboardingFunBarsCard
  | OnboardingHeroCard
  | OnboardingFaqCard;

export enum OnboardingCardTypes {
  FunBars = 'funBars',
  Info = 'info',
  InfoTwo = 'infoTwo',
  Hero = 'hero',
  Video = 'video',
  Faq = 'faq',
}

export interface OnboardingInfoCard {
  type: OnboardingCardTypes.Info;
  heading?: string;
  image?: string;
  title?: string;
  body?: string;
}

export interface OnboardingInfoCardTwo {
  type: OnboardingCardTypes.InfoTwo;
  ctaButtonText?: string;
  body?: string;
  bodyImage?: string;
  belowBodyImage?: string;
  heading?: string;
  image?: string;
  isImageFullCoverage?: boolean;
  isSkippable?: boolean;
  title?: string;
  video?: string;
  stepText?: string;
}

export type FaqItem = {
  label?: string;
  image?: string;
  title?: string;
  listElements?: string[];
  bottomLink?: {
    label: string;
    url: string;
  };
};

export interface OnboardingFaqCard {
  type: OnboardingCardTypes.Faq;
  ctaButtonText?: string;
  body?: string;
  image?: string;
  title?: string;
  stepText?: string;
  items: FaqItem[];
}

export interface OnboardingHeroCard {
  type: OnboardingCardTypes.Hero;
  ctaButtonText?: string;
  body?: string;
  desktopBackgroundImage?: string;
  mobileBackgroundImage?: string;
  title?: string;
  stepText?: string;
}

export interface OnboardingVideoCard {
  type: OnboardingCardTypes.Video;
  heading?: string;
  image?: string;
  title?: string;
  youtubeVideoId: string;
}

export interface OnboardingFunBarsCard {
  type: OnboardingCardTypes.FunBars;
  title?: string;
}

export type OnboardingQuizScore = {
  id: string;
  name: string;
  breakpoints: number[];
};

export type OnboardingNeurotypeMatrixItem = {
  id: string;
  preferredFocusGenreNames: string[];
  preferredFocusNeuralEffectLevels: string[];
  resultCards: OnboardingCardType[];
  onboardingMusicUrl?: string;
  paywallBenefits?: string[];
};

export type OnboardingQuizSpecification = {
  id: string;
  questions: OnboardingQuizQuestion[];
  scores: OnboardingQuizScore[];
  neurotypeMatrix: OnboardingNeurotypeMatrixItem[][];
};

export type OnboardingQuizQuestion = {
  answers: OnboardingQuizAnswerType[];
  description?: string;
  hasPrivacyNotice?: boolean;
  image?: string;
  scoreId: string;
  title?: string;
  stepText?: string;
};

export type OnboardingQuizAnswerType = OnboardingQuizAnswer | OnboardingQuizMusicAnswer;

export enum OnboardingQuizAnswerTypes {
  Default = 'Default',
  Music = 'Music',
}

export type OnboardingTestimonials = {
  text: string;
  author: string;
};

export type OnboardingQuizAnswer = {
  type: OnboardingQuizAnswerTypes.Default;
  title?: string;
  description?: string;
  value?: number;
  responseCards?: OnboardingCardType[];
  testimonials?: OnboardingTestimonials[];
  benefits?: string[];
  dynamicActivityId?: string;
  userPropertyToSet?: {
    key: string;
    value: string | number | boolean;
  };
};

export type OnboardingQuizMusicAnswer = {
  type: OnboardingQuizAnswerTypes.Music;
  title?: string;
  description?: string;
  imageUrl: string;
  trackUrl: string;
  value?: number;
  responseCards?: OnboardingCardType[];
  testimonials?: OnboardingTestimonials[];
  benefits?: string[];
  dynamicActivityId?: string;
  userPropertyToSet?: {
    key: string;
    value: string | number | boolean;
  };
};

export enum OnboardingSteps {
  MentalStates = 'mentalStates',
  Cards = 'cards',
  Quiz = 'quiz',
  Results = 'results',
  Commitment = 'commitment',
  Funbars = 'funbars',
}

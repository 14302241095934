import { Assets } from '../utils/assets';
import styled, { css } from 'styled-components';
import { TransitionStatus } from 'react-transition-group';

export const HEADER_HEIGHT = 3.5;
export const HEADER_PADDING = 1.25;

const SharedPanelStyles = css`
  position: relative;
  width: 100%;
  background-color: #0d0c11;
  background-image: url(${Assets.images.blurBg.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Container = styled.div`
  display: flex;
  height: 100dvh;
  padding: 8px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1);
  position: relative;

  @media (max-width: 430px) {
    padding: 0px;
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  flex-grow: 1;
  flex-direction: row;
  position: relative;
`;

export const Header = styled.div`
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  height: 48px;
  width: 48px;
`;

export const SidebarIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const LeftPanel = styled.div`
  ${SharedPanelStyles}
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 8px;
  position: relative;
`;

export const RightPanel = styled.div<{ isCollapsed: boolean; isMobileView: boolean }>`
  ${SharedPanelStyles}
  width: ${({ isMobileView, isCollapsed }) =>
    isMobileView ? '100%' : isCollapsed ? '0' : '450px'};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? '0' : '8px')};
  border: ${({ isCollapsed }) => (isCollapsed ? 'none' : '1px solid rgba(255, 255, 255, 0.2)')};
  margin-left: ${({ isCollapsed, isMobileView }) => (isCollapsed || isMobileView ? '0' : '8px')};
  transition: width 0.5s ease;
  overflow-y: hidden;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 430px) {
    border-radius: 0px;
  }
`;

export const CollapsedRightPanelWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  cursor: pointer;
`;

export const SideDeckWrapper = styled.div<{ animationState: TransitionStatus }>`
  display: flex;
  width: 100%;
  height: 100%;
  transition: opacity ${({ animationState }) => (animationState === 'exiting' ? '0.1s' : '0.3s')}
    ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
`;

export const SideDeckIconWrapper = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const CollapseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
`;

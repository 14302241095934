import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const WidgetWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const LeftHeaderContainer = styled.div<{ isMobileView: boolean }>`
  display: ${({ isMobileView }) => (isMobileView ? 'none' : 'flex')};
  flex-direction: row;
  gap: 16px;
  margin-left: 16px;
  width: 100%;
`;

export const RightHeaderContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  width: 100%;
`;

import { MembershipWidget } from '@Memberships';
import { useShouldRenderMembershipWidget } from '../../domains/Memberships/components/MembershipWidget/hooks/useShouldRenderMembershipWidget';
import { ReferAFriendWidget } from '../../domains/Memberships/components/ReferAFriendWidget';
import { useShouldRenderReferAFriend } from '../../domains/Memberships/components/ReferAFriendWidget/hooks/useShouldRenderReferAFriend';
import { DesktopAnnouncementBannerContainer } from '../../domains/Memberships/components/DesktopAnnouncementBanner/DesktopAnnouncementBanner.container';
import { ProductSurveyBanner } from '../../domains/Memberships/components/ProductSurveyBanner/ProductSurveyBanner.container';
import { useShouldRenderProductSurvey } from '../../domains/Memberships/components/ProductSurveyBanner/hooks/useShouldRenderProductSurvey';
import { useTeamSubscriptionInfo } from '../../hooks/useHasTeamSubscription';
import { useIsMobileView } from '../../hooks/useIsMobileView';

import * as S from './Widget.styles';

export const Widget = () => {
  const { isDataReady } = useTeamSubscriptionInfo();
  const shouldRenderMembershipWidget = useShouldRenderMembershipWidget();
  const shouldRenderReferAFriend = useShouldRenderReferAFriend();
  const shouldRenderProductSurvey = useShouldRenderProductSurvey();
  const isMobileView = useIsMobileView();

  const renderFullWidget = () => {
    return shouldRenderProductSurvey ? <ProductSurveyBanner /> : null;
  };

  const renderLeftWidget = () => {
    return <DesktopAnnouncementBannerContainer />;
  };

  const renderRightWidget = () => {
    if (!isDataReady) {
      // not rendering widgets until we fetch all the necessary data (teams subscription)
      return null;
    }

    if (shouldRenderMembershipWidget) {
      return (
        <S.WidgetWrapper>
          <MembershipWidget testid="membershipWidgetSessionHeader" />
        </S.WidgetWrapper>
      );
    }

    if (shouldRenderReferAFriend) {
      return (
        <S.WidgetWrapper>
          <ReferAFriendWidget />
        </S.WidgetWrapper>
      );
    }
  };

  return (
    <>
      {renderFullWidget() ? (
        <S.Container>{renderFullWidget()}</S.Container>
      ) : (
        <S.Container>
          <S.LeftHeaderContainer isMobileView={isMobileView}>
            {renderLeftWidget()}
          </S.LeftHeaderContainer>
          <S.RightHeaderContainer>{renderRightWidget()}</S.RightHeaderContainer>
        </S.Container>
      )}
    </>
  );
};

import { OnboardingSpecification } from '../types';

type OnboardingSpecs = {
  hasCards: boolean;
  hasQuiz: boolean;
};

export const getOnboardingSpecs = (
  mentalStateId: string,
  onboardingSpecificationRaw: {
    focus: OnboardingSpecification;
    sleep: OnboardingSpecification;
    meditate: OnboardingSpecification;
    relax: OnboardingSpecification;
  },
): OnboardingSpecs => {
  const defaultSpecs = {
    hasCards: false,
    hasQuiz: false,
  };

  const specs = {
    focus: onboardingSpecificationRaw.focus,
    sleep: onboardingSpecificationRaw.sleep,
    meditate: onboardingSpecificationRaw.meditate,
    relax: onboardingSpecificationRaw.relax,
  };

  const selectedSpec = specs[mentalStateId as keyof typeof specs];

  if (!selectedSpec) {
    return defaultSpecs;
  }

  return {
    hasCards: selectedSpec.cards.length > 0,
    hasQuiz: Boolean(selectedSpec.quizSpecification),
  };
};

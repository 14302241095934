import recombee from 'recombee-js-api-client';
import * as Sentry from '@sentry/react';
import { RECOMBEE_DATABASE_ID, RECOMBEE_PUBLIC_TOKEN } from '../../globals';

class Recombee {
  client: recombee.ApiClient | null;

  constructor() {
    try {
      this.client = new recombee.ApiClient(RECOMBEE_DATABASE_ID, RECOMBEE_PUBLIC_TOKEN, {
        region: 'us-west',
      });
    } catch (error) {
      this.client = null;
      Sentry.captureException('Failed to initialize Recombee', {
        extra: { error },
      });
    }
  }

  async sendViewPortion(params: { userId: string | null; trackId: string; viewPortion: number }) {
    try {
      if (!params.userId) throw new Error('userId is null');

      const request = new recombee.SetViewPortion(
        params.userId,
        params.trackId,
        params.viewPortion,
        { cascadeCreate: true },
      );
      await this.client?.send(request);
    } catch (error) {
      Sentry.captureException('Failed to send view portion to Recombee', {
        extra: { params, error },
      });
    }
  }
}

const RecombeeInstance = new Recombee();
export { RecombeeInstance };

import { useCallback, useEffect } from 'react';
import { ProductSurveyBannerDisplay } from './ProductSurveyBanner.display';
import { Analytics } from '../../../../utils/analytics';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { useDispatch } from 'react-redux';
import { trackSurveyBannerExperimentExposure } from '../../../../domains/Utils/useAmplitudeExperiments';

export function ProductSurveyBanner() {
  const dispatch = useDispatch();

  useEffect(() => {
    trackSurveyBannerExperimentExposure();
  }, []);

  const handleClickClose = useCallback(() => {
    dispatch(uiSliceActions.setIsProductSurveyDismissed(true));
    Analytics.logEvent('product_survey_banner_dismiss');
  }, [dispatch]);

  const handleClickSurvey = useCallback(() => {
    Analytics.logEvent('product_survey_banner_click');
  }, []);

  return (
    <ProductSurveyBannerDisplay onClickClose={handleClickClose} onClickSurvey={handleClickSurvey} />
  );
}
